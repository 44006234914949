import { graphql, Link, StaticQuery } from 'gatsby';
import React from 'react';
import Logo from '../../images/homelogo.png';
import Grid from '../grid/grid';
import GridItem from '../grid/item';

const MainNav = () => (
  <StaticQuery
    query={graphql`
      query MainNavQuery {
        site {
          siteMetadata {
            portalUrl
          }
        }
      }
    `}
    render={data => (
      <Grid justifyContent="end">
        <GridItem auto>
          <ul className="c-main-nav">
            <li className="responsive-sidebar-header">
              <Link to="/" activeClassName="active">
                <picture>
                  <source media="(max-width: 768px)" />
                  <img src={Logo} alt="ZooPharm" className="logo-menu-responsive" />
                </picture>
              </Link>
            </li>
            {/* <li className="c-main-nav-item">
              <Link to="/categories/companion-animals/" activeClassName="active">
                Companion Animals
              </Link>
            </li> */}
            {/* <li className="c-main-nav-item">
              <Link to="/categories/lab-animals/" activeClassName="active">
                Lab Animals
              </Link>
            </li> */}
            {/* <li className="c-main-nav-item">
              <Link to="/categories/wildlife/" activeClassName="active">
                Wildlife
              </Link>
            </li> */}
            {/* <li className="c-main-nav-item">
              <Link to="/categories/zoo-animals/" activeClassName="active">
                Zoo Animals
              </Link>
            </li> */}
            <li className="c-main-nav-item">
              <Link to="/contact-us/" activeClassName="active">
                Contact Us
              </Link>
            </li>
            <li className="c-main-nav-item zoo-pharm-item responsive-item">
              <Link to="/register-for-access/" activeClassName="active">
                Register for Access
              </Link>
            </li>
            <li className="c-main-nav-item zoo-pharm-item responsive-item">
              <Link to="/online-refills/" activeClassName="active">Online Refills</Link>
            </li>
            <li className="c-main-nav-item zoo-pharm-item responsive-item">
              <Link to="/order-now/" activeClassName="active">How To Order</Link>
            </li>
            <li className="c-main-nav-item zoo-pharm-item responsive-item">
              <Link to="/about-us/" activeClassName="active">About Us</Link>
            </li>
            <li className="c-main-nav-item zoo-pharm-item responsive-item">
              <Link to="/news-and-events/" activeClassName="active">News & Events</Link>
            </li>
            <li className="c-main-nav-item zoo-pharm-item responsive-item">
              <Link to="/faqs/" activeClassName="active">FAQs</Link>
            </li>
            <li className="c-main-nav-item zoo-pharm-item responsive-item">
              <a href={data.site.siteMetadata.portalUrl} target="_blank" rel="noopener noreferrer">Login</a>
            </li>
          </ul>
        </GridItem>
      </Grid>
    )}
  />
);

export default MainNav;
